﻿$(document).ready(function () {
    if ($('div#divStudentOfferForm').length) {

        var autocomplete;

        var initAutocomplete = () => {
            // Create the autocomplete object, restricting the search predictions to
            autocomplete = new google.maps.places.Autocomplete(AddressLookup,
                {
                    fields: ["address_components"],
                    types: ["address"],
                });
            autocomplete.setComponentRestrictions({
                country: ["us"]
            });
            // When the user selects an address from the drop-down, populate the
            // address fields in the form.
            autocomplete.addListener("place_changed", fillInAddress);
        };

        initAutocomplete();


        function fillInAddress() {
            // Get the place details from the autocomplete object.
            var place = autocomplete.getPlace();
            var address1 = "";
            var postcode = "";

            $("#MailingStreet").val("");
            $("#MailingCity").val("");
            $("#MailingStateProvince").val("");
            $("#MailingPostalCode").val("");

            // Get each component of the address from the place details,
            // and then fill-in the corresponding field on the form.
            // place.address_components are google.maps.GeocoderAddressComponent objects
            // which are documented at http://goo.gle/3l5i5Mr
            place.address_components.forEach((component) => {
                var componentType = component.types[0];

                switch (componentType) {
                    case "street_number":
                        {
                            address1 = `${component.long_name} ${address1}`;
                            break;
                        }

                    case "route":
                        {
                            address1 += component.short_name;
                            break;
                        }

                    case "postal_code":
                        {
                            postcode = `${component.long_name}${postcode}`;
                            break;
                        }

                    case "postal_code_suffix":
                        {
                            postcode = `${postcode}-${component.long_name}`;
                            break;
                        }
                    case "locality":
                        $("#MailingCity").val(component.long_name);
                        clearValidation("MailingCity");
                        break;
                    case "administrative_area_level_1":
                        {
                            $("#MailingStateProvince").val(component.short_name);
                            clearValidation("MailingStateProvince");
                            break;
                        }
                }
            });

            $("#MailingStreet").val(address1);
            clearValidation("MailingStreet");
            $("#MailingPostalCode").val(postcode);
            clearValidation("MailingPostalCode");
            $("#MailingStreet").focus();
        };


        $("#SelectedFSState").change(function () {
            $.getJSON('/api/bulkmagazines/cities',
                { state: $("#SelectedFSState").val()}
            )
            .done(function (data) {
                $("#SelectedFSCity").empty();
                var opt = new Option("-- Select --", "", true, true);
                $("#SelectedFSCity").append(opt);
                $.each(data, function (index, item) {
                    var opt = new Option(item, item);
                    $("#SelectedFSCity").append(opt);
                });
            })
            .fail(function (jqXHR, textStatus, err) {
                $("#SelectedFSCity").empty();
                var opt1 = new Option("--", "", true, true);
                $("#SelectedFSCity").append(opt1);
            });

            $("#SelectedFlightSchool").empty();
            var clearOption = new Option("--", "", true, true);
            $("#SelectedFlightSchool").append(clearOption);
        });

        $("#SelectedFSCity").change(function () {
            $.getJSON('/api/bulkmagazines/flightschools',
                { state: $("#SelectedFSState").val(), city: $("#SelectedFSCity").val() }
            )
            .done(function (data) {
                $("#SelectedFlightSchool").empty();
                var opt = new Option("-- Select --", "", true, true);
                $("#SelectedFlightSchool").append(opt);
                $.each(data, function (index, item) {
                    var opt = new Option(item.name, item.id);
                    $("#SelectedFlightSchool").append(opt);
                });
            })
            .fail(function (jqXHR, textStatus, err) {
                $("#SelectedFlightSchool").empty();
                var opt1 = new Option("--", "", true, true);
                $("#SelectedFlightSchool").append(opt1);
            });
        });

        $('input:radio[name="SchoolLookup"]').change(function () {
            if ($(this).val() == 'Lookup') {
                $('#fsOther').hide();
                $('#fsLookup').show();
            } else {
                $('#fsLookup').hide();
                $('#fsOther').show();
            }
        });


        $('#FlightStudentConfirm').change(function () {
            if (this.checked) {
                enableEnrollButton();
            } else {
                disableEnrollButton();
            }
        });

        var enableEnrollButton = () => {
            $('#btnEnroll').removeClass('join-disable-button');
            $('#btnEnroll').prop('disabled', false);
        }

        var disableEnrollButton = () => {
            $('#btnEnroll').addClass('join-disable-button');
            $('#btnEnroll').prop('disabled', true);
        }

        var clearValidation = (field) => {
            var theField = $('#' + field);
            if (theField.val()) {
                theField.removeClass("input-validation-error").addClass("valid");
                $("span[data-valmsg-for='" + field + "']").removeClass("field-validation-error").addClass("field-validation-valid");
                $("span[data-valmsg-for='" + field + "']").empty();
            }
        };

        $(".submitButtonSO").click(function () {
            var theForm = $(this).closest('form');

            if (theForm.valid()) {
                var progressBar = new Sfpb_progressBar();

                $('#btnEnroll').hide();
                $(this).hide();
                $('body').addClass('progressbar-modal');
                progressBar.start();
                $(".clsSubmitButton").append('<div id="aopaSpinner" class="spinner spinner--clockwise"></div>');
                theForm.submit();
            }
            else {
                var firstInstance = $('.input-validation-error').first();
                var offsetTop = firstInstance.offset().top - 100;

                $('html, body').animate({
                    scrollTop: offsetTop
                }, 500);
            }
        });
    }
});
