﻿$(document).ready(function () {
    if ($('div#divEFIRCPurchaseForm').length) {
        var theForm = $('#frmEFIRCPurchase');

        var efStripe = Stripe($('#sapik').val());

        var efElements = efStripe.elements();
        var efStyle = {
            base: {
                backgroundColor: '#ffffff',
                fontSize: '0.9375rem',
                fontFamily: 'Roboto, Helvetica Neue, sans-serif',
                lineHeight: '42px',
                ':focus': {
                    border: '10px solid black'
                }
            }
        };
        var efCardElement = efElements.create("card", { style: efStyle, hidePostalCode: true, disableLink: true });
        efCardElement.mount("#card-element");


        efCardElement.on('change',
            function (event) {
                var displayError = document.getElementById('card-errors');
                if (event.error) {
                    displayError.textContent = event.error.message;
                } else {
                    displayError.textContent = '';
                }

                $('#CardBrand').val(event.brand);
            });

        var cardElement = document.getElementById("card-element");

  
        var checkCardFields = () => {
            var isValid = false;

            if ($('#ShowStripeForm').val() === "True") {
                var cardElement = document.querySelector('#card-element');

                isValid = cardElement.classList.contains('StripeElement--complete');

                var brand = $('#CardBrand').val();

                if (isValid &&
                    (brand === 'visa' || brand === 'mastercard' || brand === 'amex' || brand === 'discover')) {
                    $('#card-errors').html('');
                } else {
                    if ($('#card-errors').is(':empty')) {
                        $('#card-errors').html('Please check your credit card information.');
                    }
                }

            } else {
                isValid = true;
            }


            return isValid;
        }

        $(".formSubmitButtonEFP").click(function () {
            var progressBar = new Sfpb_progressBar();
            if (cardElement !== null) {
                var ccValid = checkCardFields();

                if (theForm.valid() && ccValid) {


                    $(this).hide();
                    $('body').addClass('progressbar-modal');
                    progressBar.start();
                    $(".clsSubmitButton").prepend('<div id="aopaSpinner" class="spinner spinner--clockwise"></div>');

                    efStripe.createToken(efCardElement, { name: $("#CardName").val() })
                        .then(function (result) {
                            if (result.token !== null) {
                                $("#CardToken").val(JSON.stringify(result.token));
                                theForm.submit();
                            } else {
                                if ($('#card-errors').is(':empty')) {
                                    $('#card-errors').html('Please check your credit card information.');
                                }
                                var spinner = document.getElementById('aopaSpinner');
                                spinner.parentElement.removeChild(spinner);
                                progressBar.kill();
                                $('body').removeClass('progressbar-modal');
                                $(this).show();
                            }
                        });
                }
            } else {
                if (theForm.valid()) {
                    $(this).hide();
                    progressBar.start();
                    $(".clsSubmitButton").prepend('<div id="aopaSpinner" class="spinner spinner--clockwise"></div>');
                    theForm.submit();
                }
            }
        });
    }
});