﻿$(document).ready(function () {
    if ($('div#divAddUpdateAar').length) {

        var aareStripe = Stripe($('#sapik').val());


        var aareElements = aareStripe.elements();
        var aareStyle = {
            base: {
                backgroundColor: '#ffffff',
                fontSize: '0.9375rem',
                fontFamily: 'Roboto, Helvetica Neue, sans-serif',
                lineHeight: '42px',
                ':focus': {
                    border: '10px solid black'
                }
            }
        };
        var aareCardNumberElement = aareElements.create("card", { style: aareStyle, hidePostalCode: true, disableLink: true });
        aareCardNumberElement.mount("#card-element");


        aareCardNumberElement.on('change',
            function (event) {
                var displayError = document.getElementById('card-errors');
                if (event.error) {
                    displayError.textContent = event.error.message;
                } else {
                    displayError.textContent = '';
                }

                $('#CardBrand').val(event.brand);
            });

        var cardElement = document.getElementById("card-element");


        var checkCardFields = () => {
            var isValid = false;

            isValid = cardElement.classList.contains('StripeElement--complete');

            var brand = $('#CardBrand').val();

            if (isValid &&
                (brand === 'visa' || brand === 'mastercard' || brand === 'amex' || brand === 'discover')) {
                $('#card-errors').html('');
            } else {
                if ($('#card-errors').is(':empty')) {
                    $('#card-errors').html('Please check your credit card information.');
                }
            }


            return isValid;
        }
                 

        $(".submitButtonAARE").click(function () {
            var theForm = $(this).closest('form');

            var ccValid = checkCardFields();

            if (theForm.valid() && ccValid) {
                $('#btnEnroll').hide();
                $(this).hide();
                $(".clsSubmitButton").prepend('<div id="aopaSpinner" class="spinner spinner--clockwise"></div>');


                aareStripe.createToken(aareCardNumberElement, { name: $("#CardName").val() })
                    .then(function (result) {
                        if (result.token !== null) {
                            $("#CardToken").val(JSON.stringify(result.token));
                            theForm.submit();
                        } else {
                            if ($('#card-errors').is(':empty')) {
                                $('#card-errors').html('Please check your credit card information.');
                            }
                            var spinner = document.getElementById('aopaSpinner');
                            spinner.parentElement.removeChild(spinner);
                            $(this).show();
                            $('#btnEnroll').show();
                        }
                    });
            }
        });

    }
});
