﻿/*
Custom script for validating CC Expiration Date
*/
(function ($) {
    $.validator.addMethod("asnvolunteercheck", function (value, element, params) {

        var result = true;

        var doCheck = $(`#${params.checkforexistingfield}`).val();


        var data = $.trim(value);


        if (doCheck === "True" && data.length >= 3 && data.length <= 5) {
            $.ajax({
                url: "/api/asnairportsearch?identifier=" + data,
                async: false,
                dataType: "json",
                success: function (returnData) {
                    if (returnData && returnData.length === 1 && returnData[0].contactId) {
                        result = false;
                    }
                }
            });
        }

        return result;
    });

    jQuery.validator.unobtrusive.adapters.add("asnvolunteercheck", ["checkforexistingfield"],
        function (options) {
            options.rules['asnvolunteercheck'] = {
                checkforexistingfield: options.params.checkforexistingfield
            };
            options.messages['asnvolunteercheck'] = options.message;
        });
}(jQuery));
